

















import TableFilter from "@/components/shell/TableFilter.vue";
import {
  adminItems,
  contactItems,
  courseItems,
  dashboardItems,
  studyplanItems,
  MenuItem,
} from "@/shared/helpers/shellHelpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { watchRoutePathImmediate } from "@/shared/helpers/shellHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, ref } from "@vue/composition-api";

// TODO Fragile handling of menu items which should be refactored, the menu
// item logic could be moved to beforeEnter hooks on parent routes instead
export default defineComponent({
  name: "TheDrawer",
  components: { TableFilter },
  setup() {
    const items = ref<MenuItem[]>([]);
    const router = useRouter();
    const store = useStore<StoreState>();

    const updateMenuItems = async (routePath?: string) => {
      if (!routePath) {
        items.value = [];
      }
      if (routePath?.includes("/kurs")) {
        items.value = (await courseItems(store, routePath, +router.currentRoute.params.id, router)) ?? [];
      }
      if (routePath?.includes("/studieplaner")) {
        items.value = studyplanItems(router);
      }
      if (routePath?.includes("/admin")) {
        items.value = adminItems(router);
      }
      if (routePath?.includes("/adressebok")) {
        items.value = contactItems(router);
      }
      //  Needs to be at the bottom!!!!!
      if (routePath?.includes("/home")) {
        items.value = dashboardItems(router);
      }
    };

    watchRoutePathImmediate(updateMenuItems);

    return { items };
  },
});
