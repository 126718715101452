



import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheSkeletonLoader",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
});
